<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style="background-image: url(../assets/media/illustrations/dozzy-1/14.png)">
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <!--begin::Logo-->
        <a href="/" class="mb-12">
          <img alt="Logo" src="../assets/images/favicon.svg" class="h-40px" style="border-radius: 10px"/>
        </a>
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <!--begin::Form-->
          <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" @submit.prevent="this.loginAction()">
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1 class="text-dark mb-3">Đăng nhập</h1>
              <!--end::Title-->
            </div>
            <!--begin::Heading-->
            <!--begin::Input group-->
            <div class="fv-row mb-10 text-start">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0">Email</label>
              <!--end::Label-->
              <!--begin::Input-->
              <input class="form-control form-control-lg form-control-solid" type="text" name="email" placeholder="Nhập email" autocomplete="off" v-model="formData.username" required/>
              <!--end::Input-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="fv-row mb-10">
              <!--begin::Wrapper-->
              <div class="d-flex flex-stack mb-2">
                <!--begin::Label-->
                <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                <!--end::Label-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Input-->
              <input class="form-control form-control-lg form-control-solid" type="password" placeholder="Nhập mật khẩu" name="password" autocomplete="off" v-model="formData.password" required/>
              <!--end::Input-->
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Submit button-->
              <button class="btn btn-lg btn-primary w-100 mb-5" type="submit">
                <span class="indicator-label">Đăng nhập</span>
              </button>
            </div>
            <!--end::Actions-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
</template>

<script>
import requestUtil from '@/utils/request-util';
import {mapActions} from "vuex";
export default {
  name: 'LoginAdmin',
  data() {
    return {
      formData: {
        username: '',
        password: ''
      },
    };
  },
  computed: {
  },
  created() {
    localStorage.getItem('auth');
    if (localStorage.getItem('auth')) {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions(['login']),
    async loginAction() {
      try {
        const response = await requestUtil.post('/login', this.formData);
        if (!response.data.info.isAdmin) {
          this.$notify({
            title: 'Bạn không có quyền truy cập',
            type: 'warn',
          });
          return
        }
        await this.login(true);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('auth', 'true');
        localStorage.setItem('user_id', response.data.info.id);
        localStorage.setItem('is_admin', response.data.info.isAdmin);
        localStorage.setItem('name', response.data.info.name);
        localStorage.setItem('email', response.data.info.email);
        this.$notify({
          title: 'Đăng nhập thành công',
          type: 'success',
        });
        await this.$router.push('/');
      } catch (error) {
        this.$notify({
          title: 'Có lỗi xảy ra',
          type: 'warn',
          text: error.response.data.message
        });
      }
    },
  },
};
</script>

<style scoped>
/* Add your page-specific styles here */
</style>
