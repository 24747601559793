<template>
  <div class="card">
    <!--begin::Card head-->
    <div class="card-header card-header-stretch row">
      <!--begin::Title-->
      <div class="card-title d-flex align-items-center row">
        <h3 class="fw-bolder m-0 text-gray-800 col-md-6">Banner ads</h3>
        <div class="col-md-6">
          <button v-b-modal.modal-affiliate-banner class="btn btn-primary text-end float-end ">Thêm mới</button>
        </div>
      </div>
    </div>
    <!--end::Card head-->
    <!--begin::Card body-->
    <div class="card-body">
      <!--begin::Tab Content-->
      <div class="tab-content">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
          <!--begin::Col-->
          <div class="col-md-6 col-xxl-4" v-for="(item, index) in this.affiliateBanners" :key="index">
            <!--begin::Card-->
            <div class="card">
              <!--begin::Card body-->
              <div class="card-body d-flex flex-center flex-column p-9">
                <div class="banner mb-3">
                  <img :src="$getImage(item.image)" alt="image">
                </div>
                <!--begin::Avatar-->
                <div class="symbol symbol-65px symbol-circle mb-2">
                  <div>
                    <a href="">#{{ item.id }}</a>
                  </div>
                  <a :href="item.link" target="_blank"
                     class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{{ item.name }}</a>
                </div>
                <!--end::Info-->
                <!--begin::Follow-->
                <div>
                  <a href="#" class="btn btn-sm btn-light-primary float-start me-2" @click="updateItem(item)" v-b-modal.modal-affiliate-banner>Cập nhật</a>
                  <a href="#" class="btn btn-sm btn-light-danger float-start" @click="deleteItem(item)">Xoá</a>
                </div>
                <!--end::Follow-->
              </div>
              <!--begin::Card body-->
            </div>
            <!--begin::Card-->
          </div>
          <!--end::Col-->
        </div>
      </div>
      <b-modal id="modal-affiliate-banner" size="xl" title="Affiliate Banner" v-model="showModal" hide-footer>
        <div class="form-group mb-3">
          <label class="fs-6 fw-bold">Ảnh</label>
          <div class="col-12 mb-2">
            <div v-if="this.imageUrl">
              <img :src="this.imageUrl" alt="Image Preview" style="height: 200px; width: auto"/>
            </div>
          </div>
          <div class="col-12">
            <input type="file" class="form-control" @change="onFileChange">
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="fs-6 fw-bold">ID</label>
          <div class="col-12">
            <input type="text" class="form-control" v-model="affiliateBannerBody.id" placeholder="Nhập id">
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="fs-6 fw-bold">Tên</label>
          <div class="col-12">
            <input type="text" class="form-control" v-model="affiliateBannerBody.name" placeholder="Nhập tên">
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="fs-6 fw-bold">Link</label>
          <div class="col-12">
            <input type="text" class="form-control" v-model="affiliateBannerBody.link" placeholder="Nhập đường dẫn">
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="fs-6 fw-bold">Thứ tự</label>
          <div class="col-12">
            <input type="number" class="form-control" v-model="affiliateBannerBody.sort" placeholder="Nhập thứ tự">
          </div>
        </div>
        <div class="form-group mb-3">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Đóng</button>
          <button class="btn btn-primary text-end float-end" @click="submit()">Gửi</button>
        </div>
      </b-modal>
      <!--end::Tab Content-->
    </div>
    <!--end::Card body-->
  </div>
</template>
<script>
import requestUtil from "@/utils/request-util";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AffiliateBanner',
  data() {
    return {
      showModal: false,
      isLoading: false,
      affiliateBanners: [],
      imageFile: null,
      imageUrl: null,
      affiliateBannerBody: {
        id: null,
        name: null,
        image: null,
        link: null,
        sort: null,
      }
    };
  },
  mounted() {
    this.fetchBannerAds();
  },
  unmounted() {
  },
  created() {
  },
  methods: {
    async submit() {
      await this.uploadFile();
      const response = await requestUtil.post('/affiliate/banner', this.affiliateBannerBody);
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchBannerAds();
      } else {
        this.$notify({
          title: 'Thất bại',
          type: 'error',
        });
      }
    },
    async fetchBannerAds() {
      const response = await requestUtil.get('/affiliate/banner');
      if (response.status === 200) {
        this.affiliateBanners = response.data
      }
    },
    updateItem(item) {
      this.affiliateBannerBody = item;
      this.imageUrl = item.image
    },
    async deleteItem(item) {
      const response = await requestUtil.get('/affiliate/banner/' + item.id + "/delete");
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchBannerAds();
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.imageFile = file
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imageUrl = null;
      }
    },
    async uploadFile() {
      let formData = new FormData()
      formData.append('files', this.imageFile)
      const response = await axios.post('https://api.mottruyen.vn/api/v1/storage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status !== 200) {
        this.$notify({
          title: 'Upload ảnh lỗi',
          type: 'error',
          text: 'Vui lòng thử lại'
        });
        return false
      } else {
        this.affiliateBannerBody.image = response.data[0]
        return true;
      }
    },
  },
};

</script>
