<template>
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <!--begin::Aside-->
        <!--end::Aside-->
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <!--begin::Header-->
          <div id="kt_header" class="header">
            <!--begin::Container-->
            <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between" id="kt_header_container">
              <!--begin::Page title-->
              <div class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                <!--begin::Heading-->
                <h1 class="d-flex flex-column text-dark fw-bolder my-0">Dashboard
                  <small class="text-muted fs-6 fw-bold ms-1 pt-1">Có 30 truyện mới</small></h1>
                <!--end::Heading-->
              </div>
              <!--end::Page title=-->
              <!--begin::Wrapper-->
              <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
                <!--begin::Aside mobile toggle-->
                <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                  <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
											<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
										</svg>
									</span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Aside mobile toggle-->
                <!--begin::Logo-->
                <a href="../../demo15/dist/index.html" class="d-flex align-items-center">
                  <img alt="Logo" src="../assets/media/logos/logo-2.svg" class="h-30px" />
                </a>
                <!--end::Logo-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Topbar-->
              <div class="d-flex align-items-center flex-shrink-0">
                <!--begin::Search-->
                <div id="kt_header_search" class="d-flex align-items-center w-125px w-md-150px w-lg-225px" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end">
                  <!--begin::Form-->
                  <form data-kt-search-element="form" class="w-100 position-relative" autocomplete="off">
                    <!--begin::Hidden input(Added to disable form autocomplete)-->
                    <input type="hidden" />
                    <!--end::Hidden input-->
                    <!--begin::Icon-->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen004.svg-->
                    <span class="svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="black" />
												<path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="black" />
											</svg>
										</span>
                    <!--end::Svg Icon-->
                    <!--end::Icon-->
                    <!--begin::Input-->
                    <input type="text" class="form-control bg-transparent ps-13 fs-7 h-40px" name="search" value="" placeholder="Quick Search" data-kt-search-element="input" />
                    <!--end::Input-->
                    <!--begin::Spinner-->
                    <span class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
											<span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
										</span>
                    <!--end::Spinner-->
                    <!--begin::Reset-->
                    <span class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
											<!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
											<span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
													<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
												</svg>
											</span>
                      <!--end::Svg Icon-->
										</span>
                    <!--end::Reset-->
                  </form>
                  <!--end::Form-->
                  <!--begin::Menu-->
                  <div data-kt-search-element="content" class="menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden">
                    <!--begin::Wrapper-->
                    <div data-kt-search-element="wrapper">
                      <!--begin::Recently viewed-->
                      <div data-kt-search-element="results" class="d-none">
                        <!--begin::Items-->
                        <div class="scroll-y mh-200px mh-lg-350px">
                          <!--begin::Category title-->
                          <h3 class="fs-5 text-muted m-0 pb-5" data-kt-search-element="category-title">Users</h3>
                          <!--end::Category title-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
                              <img src="../assets/media/avatars/150-1.jpg" alt="" />
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Karina Clark</span>
                              <span class="fs-7 fw-bold text-muted">Marketing Manager</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
                              <img src="../assets/media/avatars/150-3.jpg" alt="" />
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Olivia Bold</span>
                              <span class="fs-7 fw-bold text-muted">Software Engineer</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
                              <img src="../assets/media/avatars/150-8.jpg" alt="" />
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Ana Clark</span>
                              <span class="fs-7 fw-bold text-muted">UI/UX Designer</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
                              <img src="../assets/media/avatars/150-11.jpg" alt="" />
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Nick Pitola</span>
                              <span class="fs-7 fw-bold text-muted">Art Director</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
                              <img src="../assets/media/avatars/150-12.jpg" alt="" />
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Edward Kulnic</span>
                              <span class="fs-7 fw-bold text-muted">System Administrator</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Category title-->
                          <h3 class="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Customers</h3>
                          <!--end::Category title-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<img class="w-20px h-20px" src="../assets/media/svg/brand-logos/volicity-9.svg" alt="" />
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Company Rbranding</span>
                              <span class="fs-7 fw-bold text-muted">UI Design</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<img class="w-20px h-20px" src="../assets/media/svg/brand-logos/tvit.svg" alt="" />
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Company Re-branding</span>
                              <span class="fs-7 fw-bold text-muted">Web Development</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<img class="w-20px h-20px" src="../assets/media/svg/misc/infography.svg" alt="" />
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Business Analytics App</span>
                              <span class="fs-7 fw-bold text-muted">Administration</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<img class="w-20px h-20px" src="../assets/media/svg/brand-logos/leaf.svg" alt="" />
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">EcoLeaf App Launch</span>
                              <span class="fs-7 fw-bold text-muted">Marketing</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<img class="w-20px h-20px" src="../assets/media/svg/brand-logos/tower.svg" alt="" />
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column justify-content-start fw-bold">
                              <span class="fs-6 fw-bold">Tower Group Website</span>
                              <span class="fs-7 fw-bold text-muted">Google Adwords</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Category title-->
                          <h3 class="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Projects</h3>
                          <!--end::Category title-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/general/gen005.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="black" />
																		<rect x="7" y="17" width="6" height="2" rx="1" fill="black" />
																		<rect x="7" y="12" width="10" height="2" rx="1" fill="black" />
																		<rect x="7" y="7" width="6" height="2" rx="1" fill="black" />
																		<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <span class="fs-6 fw-bold">Si-Fi Project by AU Themes</span>
                              <span class="fs-7 fw-bold text-muted">#45670</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<rect x="8" y="9" width="3" height="10" rx="1.5" fill="black" />
																		<rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="black" />
																		<rect x="18" y="11" width="3" height="8" rx="1.5" fill="black" />
																		<rect x="3" y="13" width="3" height="6" rx="1.5" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <span class="fs-6 fw-bold">Shopix Mobile App Planning</span>
                              <span class="fs-7 fw-bold text-muted">#45690</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/communication/com012.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black" />
																		<rect x="6" y="12" width="7" height="2" rx="1" fill="black" />
																		<rect x="6" y="7" width="12" height="2" rx="1" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <span class="fs-6 fw-bold">Finance Monitoring SAAS Discussion</span>
                              <span class="fs-7 fw-bold text-muted">#21090</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black" />
																		<path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <span class="fs-6 fw-bold">Dashboard Analitics Launch</span>
                              <span class="fs-7 fw-bold text-muted">#34560</span>
                            </div>
                            <!--end::Title-->
                          </a>
                          <!--end::Item-->
                        </div>
                        <!--end::Items-->
                      </div>
                      <!--end::Recently viewed-->
                      <!--begin::Recently viewed-->
                      <div data-kt-search-element="main">
                        <!--begin::Heading-->
                        <div class="d-flex flex-stack fw-bold mb-5">
                          <!--begin::Label-->
                          <span class="text-muted fs-6 me-2">Recently Searched</span>
                          <!--end::Label-->
                          <!--begin::Toolbar-->
                          <div class="d-flex" data-kt-search-element="toolbar">
                            <!--begin::Preferences toggle-->
                            <div data-kt-search-element="preferences-show" class="btn btn-icon w-20px btn-sm btn-active-color-primary me-2 data-bs-toggle=" title="Show search preferences">
                              <!--begin::Svg Icon | path: icons/duotune/coding/cod001.svg-->
                              <span class="svg-icon svg-icon-1">
																<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																	<path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="black" />
																	<path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="black" />
																</svg>
															</span>
                              <!--end::Svg Icon-->
                            </div>
                            <!--end::Preferences toggle-->
                            <!--begin::Advanced search toggle-->
                            <div data-kt-search-element="advanced-options-form-show" class="btn btn-icon w-20px btn-sm btn-active-color-primary me-n1" data-bs-toggle="tooltip" title="Show more search options">
                              <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                              <span class="svg-icon svg-icon-2">
																<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																	<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
																</svg>
															</span>
                              <!--end::Svg Icon-->
                            </div>
                            <!--end::Advanced search toggle-->
                          </div>
                          <!--end::Toolbar-->
                        </div>
                        <!--end::Heading-->
                        <!--begin::Items-->
                        <div class="scroll-y mh-200px mh-lg-325px">
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/electronics/elc004.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="black" />
																		<path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="black" />
																		<path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">BoomApp by Keenthemes</a>
                              <span class="fs-7 text-muted fw-bold">#45789</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/graphs/gra001.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z" fill="black" />
																		<path d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"Kept API Project Meeting</a>
                              <span class="fs-7 text-muted fw-bold">#84050</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/graphs/gra006.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="black" />
																		<path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"KPI Monitoring App Launch</a>
                              <span class="fs-7 text-muted fw-bold">#84250</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/graphs/gra002.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M20 8L12.5 5L5 14V19H20V8Z" fill="black" />
																		<path d="M21 18H6V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">Project Reference FAQ</a>
                              <span class="fs-7 text-muted fw-bold">#67945</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="black" />
																		<path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"FitPro App Development</a>
                              <span class="fs-7 text-muted fw-bold">#84250</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
																		<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">Shopix Mobile App</a>
                              <span class="fs-7 text-muted fw-bold">#45690</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex align-items-center mb-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40px me-4">
															<span class="symbol-label bg-light">
																<!--begin::Svg Icon | path: icons/duotune/graphs/gra002.svg-->
																<span class="svg-icon svg-icon-2 svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M20 8L12.5 5L5 14V19H20V8Z" fill="black" />
																		<path d="M21 18H6V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z" fill="black" />
																	</svg>
																</span>
                                <!--end::Svg Icon-->
															</span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                              <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"Landing UI Design" Launch</a>
                              <span class="fs-7 text-muted fw-bold">#24005</span>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                        </div>
                        <!--end::Items-->
                      </div>
                      <!--end::Recently viewed-->
                      <!--begin::Empty-->
                      <div data-kt-search-element="empty" class="text-center d-none">
                        <!--begin::Icon-->
                        <div class="pt-10 pb-10">
                          <!--begin::Svg Icon | path: icons/duotune/files/fil024.svg-->
                          <span class="svg-icon svg-icon-4x opacity-50">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="black" />
															<path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="black" />
															<rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="black" />
															<path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="black" />
														</svg>
													</span>
                          <!--end::Svg Icon-->
                        </div>
                        <!--end::Icon-->
                        <!--begin::Message-->
                        <div class="pb-15 fw-bold">
                          <h3 class="text-gray-600 fs-5 mb-2">No result found</h3>
                          <div class="text-muted fs-7">Please try again with a different query</div>
                        </div>
                        <!--end::Message-->
                      </div>
                      <!--end::Empty-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Preferences-->
                    <form data-kt-search-element="advanced-options-form" class="pt-1 d-none">
                      <!--begin::Heading-->
                      <h3 class="fw-bold text-dark mb-7">Advanced Search</h3>
                      <!--end::Heading-->
                      <!--begin::Input group-->
                      <div class="mb-5">
                        <input type="text" class="form-control form-control-sm form-control-solid" placeholder="Contains the word" name="query" />
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="mb-5">
                        <!--begin::Radio group-->
                        <div class="nav-group nav-group-fluid">
                          <!--begin::Option-->
                          <label>
                            <input type="radio" class="btn-check" name="type" value="has" checked="checked" />
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary">All</span>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label>
                            <input type="radio" class="btn-check" name="type" value="users" />
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Users</span>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label>
                            <input type="radio" class="btn-check" name="type" value="orders" />
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Orders</span>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label>
                            <input type="radio" class="btn-check" name="type" value="projects" />
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Projects</span>
                          </label>
                          <!--end::Option-->
                        </div>
                        <!--end::Radio group-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="mb-5">
                        <input type="text" name="assignedto" class="form-control form-control-sm form-control-solid" placeholder="Assigned to" value="" />
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="mb-5">
                        <input type="text" name="collaborators" class="form-control form-control-sm form-control-solid" placeholder="Collaborators" value="" />
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="mb-5">
                        <!--begin::Radio group-->
                        <div class="nav-group nav-group-fluid">
                          <!--begin::Option-->
                          <label>
                            <input type="radio" class="btn-check" name="attachment" value="has" checked="checked" />
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary">Has attachment</span>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label>
                            <input type="radio" class="btn-check" name="attachment" value="any" />
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Any</span>
                          </label>
                          <!--end::Option-->
                        </div>
                        <!--end::Radio group-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="mb-5">
                        <select name="timezone" aria-label="Select a Timezone" data-control="select2" data-placeholder="date_period" class="form-select form-select-sm form-select-solid">
                          <option value="next">Within the next</option>
                          <option value="last">Within the last</option>
                          <option value="between">Between</option>
                          <option value="on">On</option>
                        </select>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="row mb-8">
                        <!--begin::Col-->
                        <div class="col-6">
                          <input type="number" name="date_number" class="form-control form-control-sm form-control-solid" placeholder="Lenght" value="" />
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-6">
                          <select name="date_typer" aria-label="Select a Timezone" data-control="select2" data-placeholder="Period" class="form-select form-select-sm form-select-solid">
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                            <option value="months">Months</option>
                            <option value="years">Years</option>
                          </select>
                        </div>
                        <!--end::Col-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end">
                        <button type="reset" class="btn btn-sm btn-light fw-bolder btn-active-light-primary me-2" data-kt-search-element="advanced-options-form-cancel">Cancel</button>
                        <a href="../../demo15/dist/pages/search/horizontal.html" class="btn btn-sm fw-bolder btn-primary" data-kt-search-element="advanced-options-form-search">Search</a>
                      </div>
                      <!--end::Actions-->
                    </form>
                    <!--end::Preferences-->
                    <!--begin::Preferences-->
                    <form data-kt-search-element="preferences" class="pt-1 d-none">
                      <!--begin::Heading-->
                      <h3 class="fw-bold text-dark mb-7">Search Preferences</h3>
                      <!--end::Heading-->
                      <!--begin::Input group-->
                      <div class="pb-4 border-bottom">
                        <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                          <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Projects</span>
                          <input class="form-check-input" type="checkbox" value="1" checked="checked" />
                        </label>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="py-4 border-bottom">
                        <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                          <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Targets</span>
                          <input class="form-check-input" type="checkbox" value="1" checked="checked" />
                        </label>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="py-4 border-bottom">
                        <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                          <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Affiliate Programs</span>
                          <input class="form-check-input" type="checkbox" value="1" />
                        </label>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="py-4 border-bottom">
                        <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                          <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Referrals</span>
                          <input class="form-check-input" type="checkbox" value="1" checked="checked" />
                        </label>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="py-4 border-bottom">
                        <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                          <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Users</span>
                          <input class="form-check-input" type="checkbox" value="1" />
                        </label>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end pt-7">
                        <button type="reset" class="btn btn-sm btn-light fw-bolder btn-active-light-primary me-2" data-kt-search-element="preferences-dismiss">Cancel</button>
                        <button type="submit" class="btn btn-sm fw-bolder btn-primary">Save Changes</button>
                      </div>
                      <!--end::Actions-->
                    </form>
                    <!--end::Preferences-->
                  </div>
                  <!--end::Menu-->
                </div>
                <!--end::Search-->
                <!--begin::Activities-->
                <div class="d-flex align-items-center ms-3 ms-lg-4">
                  <!--begin::Drawer toggle-->
                  <div class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-40px h-40px" id="kt_activities_toggle">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen007.svg-->
                    <span class="svg-icon svg-icon-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black" />
												<path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black" />
											</svg>
										</span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Drawer toggle-->
                </div>
                <!--end::Activities-->
                <!--begin::Chat-->
                <div class="d-flex d-none align-items-center ms-3 ms-lg-4">
                  <!--begin::Drawer wrapper-->
                  <div class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary position-relative w-40px h-40px" id="kt_drawer_chat_toggle">
                    <!--begin::Svg Icon | path: icons/duotune/communication/com003.svg-->
                    <span class="svg-icon svg-icon-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="black" />
												<path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="black" />
											</svg>
										</span>
                    <!--end::Svg Icon-->
                    <!--begin::Bullet-->
                    <span class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                    <!--end::Bullet-->
                  </div>
                  <!--end::Drawer wrapper-->
                </div>
                <!--end::Chat-->
                <!--begin::Sidebar Toggler-->
                <!--end::Sidebar Toggler-->
              </div>
              <!--end::Topbar-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Header-->
          <!--begin::Content-->
          <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-fluid" id="kt_content_container">
              <!--begin::Row-->
              <div class="row g-5 g-lg-10">
                <!--begin::Col-->
                <div class="col-xxl-4 col-md-4 mb-xxl-10">
                  <!--begin::Mixed Widget 17-->
                  <div class="card h-md-100">
                    <!--begin::Body-->
                    <div class="card-body pt-5">
                      <!--begin::Heading-->
                      <div class="d-flex flex-stack">
                        <!--begin::Title-->
                        <h4 class="fw-bolder text-gray-800 m-0">Người dùng mới</h4>
                        <!--end::Title-->
                        <!--begin::Menu-->
                        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                          <span class="svg-icon svg-icon-2">
														<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
															<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
															</g>
														</svg>
													</span>
                          <!--end::Svg Icon-->
                        </button>
                        <!--begin::Menu 3-->
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
                          <!--begin::Heading-->
                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>
                          <!--end::Heading-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference"></i></a>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>
                            <!--begin::Menu sub-->
                            <div class="menu-sub menu-sub-dropdown w-175px py-4">
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>
                              <!--end::Menu item-->
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>
                              <!--end::Menu item-->
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>
                              <!--end::Menu item-->
                              <!--begin::Menu separator-->
                              <div class="separator my-2"></div>
                              <!--end::Menu separator-->
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <div class="menu-content px-3">
                                  <!--begin::Switch-->
                                  <label class="form-check form-switch form-check-custom form-check-solid">
                                    <!--begin::Input-->
                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />
                                    <!--end::Input-->
                                    <!--end::Label-->
                                    <span class="form-check-label text-muted fs-6">Recuring</span>
                                    <!--end::Label-->
                                  </label>
                                  <!--end::Switch-->
                                </div>
                              </div>
                              <!--end::Menu item-->
                            </div>
                            <!--end::Menu sub-->
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>
                          <!--end::Menu item-->
                        </div>
                        <!--end::Menu 3-->
                        <!--end::Menu-->
                      </div>
                      <!--end::Heading-->
                      <!--begin::Chart-->
                      <div class="d-flex flex-center w-100">
                        <div class="mixed-widget-17-chart" data-kt-chart-color="primary" style="height: 300px"></div>
                      </div>
                      <!--end::Chart-->
                      <!--begin::Content-->
                      <div class="text-center w-100 position-relative z-index-1" style="margin-top: -130px">
                        <!--begin::Text-->
                        <p class="fw-bold fs-4 text-gray-400 mb-6">Long before you sit down to put the
                          <br />make sure you breathe</p>
                        <!--end::Text-->
                        <!--begin::Action-->
                        <div class="mb-9 mb-xxl-1">
                          <a href='#' class="btn btn-danger fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">Increase Users</a>
                        </div>
                        <!--ed::Action-->
                      </div>
                      <!--end::Content-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::Mixed Widget 17-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-xxl-4 col-md-4 mb-xxl-10">
                  <!--begin::List Widget 2-->
                  <div class="card h-md-100">
                    <!--begin::Header-->
                    <div class="card-header border-0">
                      <h3 class="card-title fw-bolder text-dark">Tác giả mới</h3>
                      <div class="card-toolbar">
                        <!--begin::Menu-->
                        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                          <span class="svg-icon svg-icon-2">
														<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
															<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
															</g>
														</svg>
													</span>
                          <!--end::Svg Icon-->
                        </button>
                        <!--begin::Menu 2-->
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu separator-->
                          <div class="separator mb-3 opacity-75"></div>
                          <!--end::Menu separator-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Ticket</a>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Customer</a>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                            <!--begin::Menu item-->
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">New Group</span>
                              <span class="menu-arrow"></span>
                            </a>
                            <!--end::Menu item-->
                            <!--begin::Menu sub-->
                            <div class="menu-sub menu-sub-dropdown w-175px py-4">
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Admin Group</a>
                              </div>
                              <!--end::Menu item-->
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Staff Group</a>
                              </div>
                              <!--end::Menu item-->
                              <!--begin::Menu item-->
                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Member Group</a>
                              </div>
                              <!--end::Menu item-->
                            </div>
                            <!--end::Menu sub-->
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Contact</a>
                          </div>
                          <!--end::Menu item-->
                          <!--begin::Menu separator-->
                          <div class="separator mt-3 opacity-75"></div>
                          <!--end::Menu separator-->
                          <!--begin::Menu item-->
                          <div class="menu-item px-3">
                            <div class="menu-content px-3 py-3">
                              <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                            </div>
                          </div>
                          <!--end::Menu item-->
                        </div>
                        <!--end::Menu 2-->
                        <!--end::Menu-->
                      </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body pt-2">
                      <!--begin::Item-->
                      <div class="d-flex align-items-center mb-7">
                        <!--begin::Avatar-->
                        <div class="symbol symbol-50px me-5">
                          <img src="../assets/media/avatars/150-1.jpg" class="" alt="" />
                        </div>
                        <!--end::Avatar-->
                        <!--begin::Text-->
                        <div class="flex-grow-1">
                          <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">Emma Smith</a>
                          <span class="text-muted d-block fw-bold">Project Manager</span>
                        </div>
                        <!--end::Text-->
                      </div>
                      <!--end::Item-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::List Widget 2-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-xxl-4 col-md-4 mb-xxl-10">
                  <!--begin::List Widget 7-->
                  <div class="card h-md-100">
                    <!--begin::Header-->
                    <div class="card-header align-items-center border-0 mt-4">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="fw-bolder text-dark">Truyện mới</span>
                        <span class="text-muted mt-1 fw-bold fs-7">Truyện mới đăng</span>
                      </h3>
                      <div class="card-toolbar">
                        <!--begin::Menu-->
                        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                          <span class="svg-icon svg-icon-2">
														<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
															<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
															</g>
														</svg>
													</span>
                          <!--end::Svg Icon-->
                        </button>
                        <!--begin::Menu 1-->
                        <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_61bc3b7dad49f">
                          <!--begin::Header-->
                          <div class="px-7 py-5">
                            <div class="fs-5 text-dark fw-bolder">Filter Options</div>
                          </div>
                          <!--end::Header-->
                          <!--begin::Menu separator-->
                          <div class="separator border-gray-200"></div>
                          <!--end::Menu separator-->
                          <!--begin::Form-->
                          <div class="px-7 py-5">
                            <!--begin::Input group-->
                            <div class="mb-10">
                              <!--begin::Label-->
                              <label class="form-label fw-bold">Status:</label>
                              <!--end::Label-->
                              <!--begin::Input-->
                              <div>
                                <select class="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_61bc3b7dad49f" data-allow-clear="true">
                                  <option></option>
                                  <option value="1">Approved</option>
                                  <option value="2">Pending</option>
                                  <option value="2">In Process</option>
                                  <option value="2">Rejected</option>
                                </select>
                              </div>
                              <!--end::Input-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="mb-10">
                              <!--begin::Label-->
                              <label class="form-label fw-bold">Member Type:</label>
                              <!--end::Label-->
                              <!--begin::Options-->
                              <div class="d-flex">
                                <!--begin::Options-->
                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                  <input class="form-check-input" type="checkbox" value="1" />
                                  <span class="form-check-label">Author</span>
                                </label>
                                <!--end::Options-->
                                <!--begin::Options-->
                                <label class="form-check form-check-sm form-check-custom form-check-solid">
                                  <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                  <span class="form-check-label">Customer</span>
                                </label>
                                <!--end::Options-->
                              </div>
                              <!--end::Options-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="mb-10">
                              <!--begin::Label-->
                              <label class="form-label fw-bold">Notifications:</label>
                              <!--end::Label-->
                              <!--begin::Switch-->
                              <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                <label class="form-check-label">Enabled</label>
                              </div>
                              <!--end::Switch-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Actions-->
                            <div class="d-flex justify-content-end">
                              <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                              <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                            </div>
                            <!--end::Actions-->
                          </div>
                          <!--end::Form-->
                        </div>
                        <!--end::Menu 1-->
                        <!--end::Menu-->
                      </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body pt-3">
                      <!--begin::Item-->
                      <div class="d-flex align-items-sm-center mb-7">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-60px symbol-2by3 me-4">
                          <div class="symbol-label" style="background-image: url('../assets/media/stock/600x400/img-20.jpg')"></div>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <div class="d-flex flex-row-fluid flex-wrap align-items-center">
                          <div class="flex-grow-1 me-2">
                            <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Cup &amp; Green</a>
                            <span class="text-muted fw-bold d-block pt-1">Size: 87KB</span>
                          </div>
                          <span class="badge badge-light-success fs-8 fw-bolder my-2">Approved</span>
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex align-items-sm-center mb-7">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-60px symbol-2by3 me-4">
                          <div class="symbol-label" style="background-image: url('../assets/media/stock/600x400/img-19.jpg')"></div>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <div class="d-flex flex-row-fluid flex-wrap align-items-center">
                          <div class="flex-grow-1 me-2">
                            <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Yellow Background</a>
                            <span class="text-muted fw-bold d-block pt-1">Size: 1.2MB</span>
                          </div>
                          <span class="badge badge-light-warning fs-8 fw-bolder my-2">In Progress</span>
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex align-items-sm-center mb-7">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-60px symbol-2by3 me-4">
                          <div class="symbol-label" style="background-image: url('../assets/media/stock/600x400/img-25.jpg')"></div>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <div class="d-flex flex-row-fluid flex-wrap align-items-center">
                          <div class="flex-grow-1 me-2">
                            <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Nike &amp; Blue</a>
                            <span class="text-muted fw-bold d-block pt-1">Size: 87KB</span>
                          </div>
                          <span class="badge badge-light-success fs-8 fw-bolder my-2">Success</span>
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex align-items-sm-center">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-60px symbol-2by3 me-4">
                          <div class="symbol-label" style="background-image: url('../assets/media/stock/600x400/img-24.jpg')"></div>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <div class="d-flex flex-row-fluid flex-wrap align-items-center">
                          <div class="flex-grow-1 me-2">
                            <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Red Boots</a>
                            <span class="text-muted fw-bold d-block pt-1">Size: 345KB</span>
                          </div>
                          <span class="badge badge-light-danger fs-8 fw-bolder my-2">Rejected</span>
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Item-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::List Widget 7-->
                </div>
                <!--end::Col-->
              </div>

            </div>
            <!--end::Container-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
</template>

<script>
export default {
  name: 'DashboardPage',
  props: {
    msg: String
  },
  data() {
    return {
      formData: {
        username: '',
        password: ''
      },
    };
  },
  computed: {
  },
  created() {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
