import axios from 'axios';
import router from '@/router';
import store from '@/stores'; // Đảm bảo rằng bạn đã import store đúng cách

// Tạo một instance của axios với các cấu hình mặc định
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/v1", // Thay đổi URL này thành URL API của bạn
    // baseURL: "https://api.mottruyen.vn/api/v1", // Thay đổi URL này thành URL API của bạn
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000, // Thời gian chờ mặc định là 10 giây
});

// Thêm request interceptor
apiClient.interceptors.request.use(
    config => {
        // Thêm token vào header trước khi gửi request
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Thêm response interceptor
apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let formattedError = {
            status: null,
            message: '',
            data: null
        };
        // Xử lý lỗi tại đây, ví dụ như hiện thông báo lỗi
        if (error.response) {
            formattedError.status = error.response.status;
            formattedError.message = error.response.data.message || error.response.statusText;
            formattedError.data = error.response.data;
            switch (error.response.status) {
                case 401:
                    store.dispatch('setLoggedIn', false);
                    localStorage.setItem('auth', 'false');
                    router.push({ name: 'Login' });
                    break;
                // Có thể thêm các case khác để xử lý các lỗi cụ thể nếu cần
            }
        } else if (error.request) {
            // Không nhận được phản hồi từ server
            formattedError.message = 'No response received from the server';
            console.error('No response received: ', error.request);
            alert('Không có phản hồi từ server');
        } else {
            // Lỗi khi thiết lập request
            formattedError.message = `Error setting up request: ${error.message}`;
            console.error('Error setting up request: ', error.message);
            alert('Error setting up request: ' + error.message);
        }
        return Promise.reject(formattedError); // Đảm bảo rằng lỗi được trả về dưới dạng Promise reject
    }
);

// Định nghĩa các phương thức để gọi API
const requestUtil = {
    get(url, params) {
        return apiClient.get(url, { params });
    },
    post(url, data) {
        return apiClient.post(url, data);
    },
    put(url, data) {
        return apiClient.put(url, data);
    },
    delete(url) {
        return apiClient.delete(url);
    },
};

export default requestUtil;
