<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Yêu cầu nạp rút
                <small class="text-muted fs-6 fw-bold ms-1 pt-1">Số lượng yêu cầu
                  {{ requestItems.metaData.total }}</small></h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Row-->
            <div class="row g-5 g-lg-10">
              <div class="card card-flush">
                <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                  <!--begin::Card title-->
                  <div class="card-title">
                    <!--begin::Search-->
                    <div class="d-flex align-items-center position-relative my-1">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                      <span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
													<path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"></path>
												</svg>
											</span>
                      <!--end::Svg Icon-->
                      <input type="text" data-kt-ecommerce-product-filter="search"
                             class="form-control form-control-solid w-250px ps-14" placeholder="Search Product">
                    </div>
                    <!--end::Search-->
                  </div>
                  <!--end::Card title-->
                  <!--begin::Card toolbar-->
                  <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div class="w-100 mw-150px">
                      <!--begin::Select2-->
                      <select class="form-select form-select-solid select2-hidden-accessible" data-control="select2"
                              data-hide-search="true" data-placeholder="Status"
                              data-kt-ecommerce-product-filter="status"
                              data-select2-id="select2-data-7-7t3z" tabindex="-1" aria-hidden="true">
                        <option data-select2-id="select2-data-9-y8qf"></option>
                        <option value="all">Tất cả</option>
                        <option value="inactive">Chờ duyệt</option>
                        <option value="published">Hoàn thành</option>
                        <option value="scheduled">Từ chối</option>
                      </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr"
                                     data-select2-id="select2-data-8-iagj" style="width: 100%;"><span
                        class="selection"><span
                        class="select2-selection select2-selection--single form-select form-select-solid"
                        role="combobox"
                        aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
                        aria-labelledby="select2-69zs-container" aria-controls="select2-69zs-container"><span
                        class="select2-selection__rendered" id="select2-69zs-container" role="textbox"
                        aria-readonly="true" title="Status"><span
                        class="select2-selection__placeholder">Status</span></span><span
                        class="select2-selection__arrow"
                        role="presentation"><b
                        role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                           aria-hidden="true"></span></span>
                      <!--end::Select2-->
                    </div>
                    <!--begin::Add product-->
                    <!--                  <a href="javascript:void(0)" class="btn btn-primary">Thêm mới</a>-->
                    <b-button variant="primary" v-b-modal.modal-create-request>Thêm mới</b-button>
                    <b-modal id="modal-create-request" ref="createRequestModal" hide-footer title="Thêm mới yêu cầu">
                      <div class="row">
                        <div class="col-6">
                          <div class="fv-row fv-plugins-icon-container">
                            <label class="required form-label">Wallet ID</label>
                            <input type="number" v-model="dataForm.walletId" class="form-control mb-2"
                                   @blur="loadUser()"
                                   placeholder="Nhập wallet id"
                                   value="">
                            <span class="text-danger"
                                  v-if="dataFormErrorMessages.walletId">{{ dataFormErrorMessages.walletId }}</span>
                          </div>
                        </div>
                        <div class="col-6 bg-secondary-subtle border-left-1">
                          <div v-if="walletUser.user != null">
                            <div class="mb-2">{{ walletUser.user?.name }}</div>
                            <div class="mb-2">{{ walletUser.user?.email }}</div>
                            <div class="">Số dư: <span
                                class="text-danger fw-bold">{{ numberFormat(walletUser.wallet?.gold) }}</span></div>
                          </div>
                          <div class="d-flex justify-content-center align-items-center pt-4">
                            <b-spinner variant="primary" label="Spinning" v-if="loading.user"></b-spinner>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <label class="required form-label">Nạp/Rút</label>
                          <div class="row">
                            <div class="col-6">
                              <b-form-radio v-model="dataForm.type" name="some-radios" value="RECHARGE">Nạp
                              </b-form-radio>
                            </div>
                            <div class="col-6">
                              <b-form-radio v-model="dataForm.type" name="some-radios" value="WITHDRAW">Rút
                              </b-form-radio>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <label class="required form-label">Số tiền</label>
                          <input type="number" v-model="dataForm.amount" class="form-control mb-2"
                                 placeholder="Số tiền"
                                 value="">
                          <span class="text-danger"
                                v-if="dataFormErrorMessages.amount">{{ dataFormErrorMessages.amount }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <label class="required form-label">Ghi chú</label>
                          <input type="text" v-model="dataForm.note" class="form-control mb-2"
                                 placeholder="Ghi chú" value="">
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <div class="row">
                            <div class="col-6">
                              <b-button variant="secondary" class="me-3" data-bs-dismiss="modal">Đóng</b-button>
                            </div>
                            <div class="col-6 text-end">
                              <b-button variant="primary" @click="doSubmit()">Hoàn thành</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-modal>
                    <!--end::Add product-->
                  </div>
                  <!--end::Card toolbar-->
                </div>
                <!--begin::Card body-->
                <div class="card-body pt-0">
                  <!--begin::Table-->
                  <div id="kt_ecommerce_category_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                             id="kt_ecommerce_category_table">
                        <!--begin::Table head-->
                        <thead>
                        <!--begin::Table row-->
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                          <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="
												">
                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                              <input class="form-check-input" type="checkbox" data-kt-check="true"
                                     data-kt-check-target="#kt_ecommerce_category_table .form-check-input" value="1">
                            </div>
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                          >Người yêu cầu
                          </th>
                          <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Số tiền
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Trạng thái
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Ghi chú
                          </th>
                          <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Thời gian
                          </th>
                          <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="Actions">
                            Hành động
                          </th>
                        </tr>
                        <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                        <!--begin::Table row-->

                        <!--end::Table row-->


                        <tr class="even" v-for="(item, index) in this.requestItems.data" :key="index">
                          <!--begin::Checkbox-->
                          <td>
                            <div class="form-check  form-check-sm form-check-custom form-check-solid">
                              <input class="form-check-input" type="checkbox" value="1">
                            </div>
                          </td>
                          <!--end::Checkbox-->
                          <!--begin::Category=-->
                          <td>
                            <div class="text-start">
                              <div>Tài khoản: {{ item.user?.name }} ({{ item.walletId }})</div>
                              <div>Tác giả : <a
                                  :href="`https://mottruyen.vn/tac-gia/${item.author?.name}/${item.author?.id}`"
                                  target="_blank"
                              >
                                {{ item.author?.name }} ({{ item.author?.id }})
                              </a></div>
                              <div class="row">
                                <label for="">Hình thức</label>
                                <div class="logo col-12 col-md-3 text-center">
                                  <div v-if="item.paymentMethod?.type === 'BANK'"><img
                                      :src="item.paymentMethod.bank.logo" alt="" style="height: 50px; width: auto">
                                  </div>
                                  <div v-else><img src="../assets/images/momo_logo.webp" alt=""
                                                   style="height: 50px; width: auto"></div>
                                </div>
                                <div class="logo col-12 col-md-9">
                                  <div class="fw-bold">{{ item.paymentMethod?.bank?.shortName }}</div>
                                  <div>{{ item.paymentMethod?.accountNumber }}</div>
                                  <div>{{ item.paymentMethod?.accountName }}</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="fw-bold text-danger text-center" v-if="item.type === 'RECHARGE'">
                              - {{ numberFormat(item.amount) }}
                            </div>
                            <div class="fw-bold text-success text-center" v-else>
                              + {{ numberFormat(item.amount) }}
                            </div>
                          </td>
                          <!--begin::Type=-->
                          <td class="text-center">
                            <!--begin::Badges-->
                            <div class="badge badge-light-success" v-if="item.state === 'APPROVED'">Hoàn thành</div>
                            <div class="badge badge-light-secondary" v-if="item.state === 'FAIL'">Không thành công</div>
                            <div class="badge badge-light-danger" v-if="item.state === 'REJECT'">Từ chối</div>
                            <div class="badge badge-light-warning" v-if="item.state === 'PENDING'">Chờ duyệt</div>
                            <!--end::Badges-->
                          </td>
                          <!--end::Type=-->
                          <td class="text-center">
                            {{ item.note }}
                          </td>
                          <td class="text-center">
                            {{ $formatDateTime(item.createdAt) }}
                          </td>
                          <!--begin::Action=-->
                          <td class="text-end">
                            <div v-if="item.state === 'PENDING'">
                              <button class="btn btn-sm btn-success me-2" @click="changeState(item.id, 'APPROVED')">
                                Duyệt
                              </button>
                              <button class="btn btn-sm btn-danger" @click="changeState(item.id, 'REJECT')">Từ chối
                              </button>
                            </div>
                          </td>
                          <!--end::Action=-->
                        </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                    </div>
                    <div class="row">
                      <div
                          class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_ecommerce_category_table_length"><label><select
                            name="kt_ecommerce_category_table_length" aria-controls="kt_ecommerce_category_table"
                            class="form-select form-select-sm form-select-solid">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select></label></div>
                      </div>
                      <div
                          class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers"
                             id="kt_ecommerce_category_table_paginate">
                          <ul class="pagination">
                            <li class="paginate_button page-item previous disabled"
                                id="kt_ecommerce_category_table_previous"><a href="#"
                                                                             aria-controls="kt_ecommerce_category_table"
                                                                             data-dt-idx="0" tabindex="0"
                                                                             class="page-link"><i class="previous"></i></a>
                            </li>
                            <li class="paginate_button page-item active"><a href="#"
                                                                            aria-controls="kt_ecommerce_category_table"
                                                                            data-dt-idx="1" tabindex="0"
                                                                            class="page-link">1</a></li>
                            <li class="paginate_button page-item "><a href="#"
                                                                      aria-controls="kt_ecommerce_category_table"
                                                                      data-dt-idx="2" tabindex="0"
                                                                      class="page-link">2</a></li>
                            <li class="paginate_button page-item next" id="kt_ecommerce_category_table_next"><a href="#"
                                                                                                                aria-controls="kt_ecommerce_category_table"
                                                                                                                data-dt-idx="3"
                                                                                                                tabindex="0"
                                                                                                                class="page-link"><i
                                class="next"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Table-->
                </div>
                <!--end::Card body-->
              </div>
            </div>

          </div>
          <!--end::Container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</template>
<script>
import requestUtil from "@/utils/request-util";

export default {
  name: 'WalletRequest',
  data() {
    return {
      requestItems: {
        data: [],
        metaData: {}
      },
      modalShow: false,
      dataForm: {
        walletId: null,
        amount: null,
        type: 'RECHARGE',
        note: null,
      },
      walletUser: {},
      loading: {
        page: false,
        user: false,
      },
      dataFormErrorMessages: {
        walletId: null,
        amount: null,
        type: null,
        note: null,
        valid: true,
      }
    };
  },
  mounted() {
    this.fetchRequest();
  },
  created() {
  },
  methods: {
    formValid() {
      this.dataFormErrorMessages.valid = true
      this.dataFormErrorMessages = {}
      if (this.dataForm.walletId == null) {
        this.dataFormErrorMessages.walletId = "Không được để trống"
        this.dataFormErrorMessages.valid = false
      }
      if (this.dataForm.walletId <= 0) {
        this.dataFormErrorMessages.walletId = "Thông tin ví không hợp lệ"
        this.dataFormErrorMessages.valid = false
      }
      if (this.dataForm.amount == null) {
        this.dataFormErrorMessages.amount = "Không được để trống"
        this.dataFormErrorMessages.valid = false
      }
      if (this.dataForm.amount <= 0) {
        this.dataFormErrorMessages.amount = "Số tiền không hợp lệ"
        this.dataFormErrorMessages.valid = false
      }
    },
    async doSubmit() {
      //valid
      // this.formValid();
      if (this.dataFormErrorMessages.valid === false) {
        return
      }
      try {
        const response = await requestUtil.post('/admin/wallet/request', this.dataForm);
        if (response.status === 200) {
          await this.fetchRequest();
        } else {
          this.$notify({
            title: 'Lỗi xảy ra',
            type: 'warning',
          });
        }
      } catch (e) {
        this.$notify({
          title: 'Không tìm thấy người dùng',
          type: 'warning',
        });
      }
    },
    async loadUser() {
      this.loading.user = true;
      try {
        const response = await requestUtil.get('/admin/wallet/' + this.dataForm.walletId);
        if (response.status === 200) {
          this.walletUser = response.data
        } else {
          this.$notify({
            title: 'Không tìm thấy người dùng',
            type: 'warning',
          });
        }
      } catch (e) {
        this.$notify({
          title: 'Không tìm thấy người dùng',
          type: 'warning',
        });
      }
      this.loading.user = false;

    },
    async fetchRequest() {
      const response = await requestUtil.get('/wallet/request?role=admin&size=10');
      if (response.status === 200) {
        this.requestItems = response.data
      }
    },
    async changeState(id, state) {
      let dataForm = {
        requestId: id,
        state: state,
        note: ""
      }
      const response = await requestUtil.post('/wallet/request/change_state', dataForm);
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchRequest();
      } else {
        this.$notify({
          title: 'Thành công',
          type: 'success',
          text: response.data.message
        });
      }
      window.location.reload
    },
    numberFormat(value) {
      return (value ?? 0).toLocaleString('en-US');
    },
  },
};

</script>
<style>
</style>