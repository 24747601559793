<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Danh sách truyện
                <small class="text-muted fs-6 fw-bold ms-1 pt-1">Số lượng
                  {{ $numberFormat(items.metaData?.total) }}</small></h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Row-->
            <div class="row g-5 g-lg-10">
              <div class="card card-flush">
                <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                  <!--begin::Card title-->
                  <div class="card-title">
                    <!--begin::Search-->
                    <div class="d-flex align-items-center position-relative my-1">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                      <span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
													<path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"></path>
												</svg>
											</span>
                      <!--end::Svg Icon-->
                      <input type="text" data-kt-ecommerce-product-filter="search"
                             class="form-control form-control-solid w-250px ps-14" placeholder="Search Product">
                    </div>
                    <!--end::Search-->
                  </div>
                  <!--end::Card title-->
                  <!--begin::Card toolbar-->
                  <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div class="w-100 mw-150px">
                      <!--begin::Select2-->
                      <select class="form-select form-select-solid select2-hidden-accessible" data-control="select2"
                              data-hide-search="true" data-placeholder="Status"
                              data-kt-ecommerce-product-filter="status"
                              data-select2-id="select2-data-7-7t3z" tabindex="-1" aria-hidden="true">
                        <option data-select2-id="select2-data-9-y8qf"></option>
                        <option value="all">Tất cả</option>
                        <option value="inactive">Chờ duyệt</option>
                        <option value="published">Hoàn thành</option>
                        <option value="scheduled">Từ chối</option>
                      </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr"
                                     data-select2-id="select2-data-8-iagj" style="width: 100%;"><span
                        class="selection"><span
                        class="select2-selection select2-selection--single form-select form-select-solid"
                        role="combobox"
                        aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
                        aria-labelledby="select2-69zs-container" aria-controls="select2-69zs-container"><span
                        class="select2-selection__rendered" id="select2-69zs-container" role="textbox"
                        aria-readonly="true" title="Status"><span
                        class="select2-selection__placeholder">Status</span></span><span
                        class="select2-selection__arrow"
                        role="presentation"><b
                        role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                           aria-hidden="true"></span></span>
                      <!--end::Select2-->
                    </div>
                    <!--begin::Add product-->
                    <!--                  <a href="javascript:void(0)" class="btn btn-primary">Thêm mới</a>-->
                    <b-button variant="primary" v-b-modal.modal-create-request>Thêm mới</b-button>
                    <b-modal id="modal-create-request" ref="createRequestModal" hide-footer title="Thêm mới yêu cầu">
                    </b-modal>
                    <!--end::Add product-->
                  </div>
                  <!--end::Card toolbar-->
                </div>
                <!--begin::Card body-->
                <div class="card-body pt-0">
                  <!--begin::Table-->
                  <div id="kt_ecommerce_category_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                             id="kt_ecommerce_category_table">
                        <!--begin::Table head-->
                        <thead>
                        <!--begin::Table row-->
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                          <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="
												">
                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                              <input class="form-check-input" type="checkbox" data-kt-check="true"
                                     data-kt-check-target="#kt_ecommerce_category_table .form-check-input" value="1">
                            </div>
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                          >Ảnh
                          </th>
                          <th class="text-start sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Truyện
                          </th>
                          <th class="sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Tác giả
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Trạng thái
                          </th>
                          <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Thời gian
                          </th>
                          <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="Actions">
                            Hành động
                          </th>
                        </tr>
                        <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                        <tr class="even" v-for="(item, index) in this.items.data" :key="index">
                          <!--begin::Checkbox-->
                          <td>
                            <div class="form-check  form-check-sm form-check-custom form-check-solid">
                              {{ item.id }}
                            </div>
                          </td>
                          <!--end::Checkbox-->
                          <!--begin::Category=-->
                          <td>
                            <div class="text-center">
                              <a href=""
                                 class="symbol symbol-100px">
                                <img class="symbol-label" :src="$getImage(item.image)" alt="">
                              </a>
                            </div>
                          </td>
                          <td>
                            <span class="fw-bold text-black text-hover-primary">{{ item.name }}</span>
                            <br>
                            <span class="text-black-50 small">{{$numberFormat(item.view)}} Lượt xem</span> <br>
                            <span class="text-black-50 small">{{item.countChapter}} Chương</span>
                          </td>
                          <td class="text-start">
                            <div class="d-flex align-items-center p-3 mb-2">
                              <!--begin::Avatar-->
                              <div class="symbol symbol-40px symbol-circle me-3">
                                <img alt="Pic" :src="$getImage(item.author.image)" v-if="item.author.image !== null">
                                <img alt="Pic" src="../assets/images/user_default.png" v-else>
                              </div>
                              <!--end::Avatar-->
                              <!--begin::Info-->
                              <div class="d-flex flex-column">
                                <!--begin::Name-->
                                <a href="" class="fs-5 fw-bolder text-black-50 text-hover-primary me-2">{{
                                    item.author.name
                                  }}</a>
                                <!--end::Name-->
                                <!--begin::Email-->
                                <a href="" class="fw-bold text-gray-500 text-hover-primary">{{
                                    item.author.countFollower
                                  }} follower</a>
                                <!--end::Email-->

                              </div>
                              <!--end::Info-->
                            </div>

                          </td>
                          <!--begin::Type=-->
                          <td class="text-center">
                            <!--begin::Badges-->
                            <div class="badge badge-light-success" v-if="item.state === 'APPROVED'">Công khai</div>
                            <div class="badge badge-light-dark" v-if="item.state === 'UN_PUBLIC'">Riêng tư</div>
                            <div class="badge badge-light-danger" v-if="item.state === 'REJECT'">Từ chối</div>
                            <div class="badge badge-light-warning" v-if="item.state === 'PENDING'">Chờ duyệt</div>
                            <div class="badge badge-light-secondary" v-if="item.state === 'DRAFT'">Nháp</div>
                            <div class="badge badge-light-secondary" v-if="item.state === 'DELETE'">Đã xoá</div>
                            <!--end::Badges-->
                          </td>
                          <!--end::Type=-->
                          <td class="text-center">
                            {{ $formatDateTime(item.updatedAt) }}
                          </td>
                          <td class="text-end">
                            <div v-if="item.state === 'PENDING'">
                              <button class="btn btn-sm btn-success me-1" @click="changeState(item.id, 'APPROVED')">Duyệt</button>
                              <button class="btn btn-sm btn-danger me-1" @click="changeState(item.id,'REJECT')">Từ chối</button>
                            </div>
<!--                              <a href="#" class="btn btn-light btn-active-light-primary btn-sm">Actions-->
<!--                                &lt;!&ndash;begin::Svg Icon | path: icons/duotune/arrows/arr072.svg&ndash;&gt;-->
<!--                                <span class="svg-icon svg-icon-5 m-0">-->
<!--                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>-->
<!--                                  </svg>-->
<!--                                </span>-->
<!--                                &lt;!&ndash;end::Svg Icon&ndash;&gt;</a>-->
<!--                              &lt;!&ndash;begin::Menu&ndash;&gt;-->
<!--                              <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">-->
<!--                                &lt;!&ndash;begin::Menu item&ndash;&gt;-->
<!--                                <div class="menu-item px-3">-->
<!--                                  <a href="../../demo15/dist/apps/subscriptions/add.html" class="menu-link px-3">View</a>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;end::Menu item&ndash;&gt;-->
<!--                                &lt;!&ndash;begin::Menu item&ndash;&gt;-->
<!--                                <div class="menu-item px-3">-->
<!--                                  <a href="../../demo15/dist/apps/subscriptions/add.html" class="menu-link px-3">Edit</a>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;end::Menu item&ndash;&gt;-->
<!--                                &lt;!&ndash;begin::Menu item&ndash;&gt;-->
<!--                                <div class="menu-item px-3">-->
<!--                                  <a href="#" data-kt-subscriptions-table-filter="delete_row" class="menu-link px-3">Delete</a>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;end::Menu item&ndash;&gt;-->
<!--                              </div>-->
                              <!--end::Menu-->
                          </td>
                          <!--end::Action=-->
                        </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                    </div>
                    <div class="row">
                      <div
                          class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_ecommerce_category_table_length"><label><select
                            name="kt_ecommerce_category_table_length" aria-controls="kt_ecommerce_category_table"
                            class="form-select form-select-sm form-select-solid">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select></label></div>
                      </div>
                      <div
                          class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers"
                             id="kt_ecommerce_category_table_paginate">
                          <ul class="pagination">
                            <li class="paginate_button page-item previous disabled"
                                id="kt_ecommerce_category_table_previous"><a href="#"
                                                                             aria-controls="kt_ecommerce_category_table"
                                                                             data-dt-idx="0" tabindex="0"
                                                                             class="page-link"><i class="previous"></i></a>
                            </li>
                            <li class="paginate_button page-item active"><a href="#"
                                                                            aria-controls="kt_ecommerce_category_table"
                                                                            data-dt-idx="1" tabindex="0"
                                                                            class="page-link">1</a></li>
                            <li class="paginate_button page-item "><a href="#"
                                                                      aria-controls="kt_ecommerce_category_table"
                                                                      data-dt-idx="2" tabindex="0"
                                                                      class="page-link">2</a></li>
                            <li class="paginate_button page-item next" id="kt_ecommerce_category_table_next"><a href="#"
                                                                                                                aria-controls="kt_ecommerce_category_table"
                                                                                                                data-dt-idx="3"
                                                                                                                tabindex="0"
                                                                                                                class="page-link"><i
                                class="next"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Table-->
                </div>
                <!--end::Card body-->
              </div>
            </div>

          </div>
          <!--end::Container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</template>
<script>
import requestUtil from "@/utils/request-util";

export default {
  name: "StoryList",
  computed: {},
  data() {
    return {
      items: {
        data: [],
        metaData: {}
      },
      modalShow: false,
      dataForm: {},
      loading: {
        page: false,
        user: false,
      },
    };
  },
  mounted() {
    this.fetchItems();
  },
  created() {
  },
  methods: {
    async fetchItems() {
      const response = await requestUtil.get('/admin/story');
      if (response.status === 200) {
        this.items = response.data
      }
    },
    async changeState(id, state) {
      const data = {
        storyId: id,
        state: state,
        note: null
      }
      const response = await requestUtil.post('/admin/story/change_state', data);
      if (response.status === 200) {
        await this.fetchItems();
        this.$notify({
          title: 'Thành công',
          type: 'success',
          text: 'Vui lòng thử lại'
        });
      } else {
        this.$notify({
          title: 'Có lỗi xảy ra',
          type: 'error',
          text: 'Vui lòng thử lại'
        });
      }
    }
  },
};

</script>
<style>
</style>