<template>
  <div class="d-flex flex-column flex-root" @click="checkClickOutside">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Affiliate
                <small class="text-muted fs-6 fw-bold ms-1 pt-1">Số lượng {{ requestItems.metaData?.total }}</small>
              </h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Products-->
            <div class="card card-flush">
              <!--begin::Card header-->
              <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                <!--begin::Card title-->
                <div class="card-title">
                  <!--begin::Search-->
                  <div class="d-flex align-items-center position-relative my-1">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                    <span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
													<path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"></path>
												</svg>
											</span>
                    <!--end::Svg Icon-->
                    <input type="text" data-kt-ecommerce-product-filter="search"
                           class="form-control form-control-solid w-250px ps-14" placeholder="Search Product">
                  </div>
                  <br>
                  <!--end::Search-->
                </div>
                <!--end::Card title-->
                <!--begin::Card toolbar-->
                <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                  <div class="w-100 mw-150px">
                    <!--begin::Select2-->
                    <select class="form-select form-select-solid select2-hidden-accessible" data-control="select2"
                            data-hide-search="true" data-placeholder="Status" data-kt-ecommerce-product-filter="status"
                            data-select2-id="select2-data-7-7t3z" tabindex="-1" aria-hidden="true">
                      <option data-select2-id="select2-data-9-y8qf"></option>
                      <option value="all">All</option>
                      <option value="published">Published</option>
                      <option value="scheduled">Scheduled</option>
                      <option value="inactive">Inactive</option>
                    </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr"
                                   data-select2-id="select2-data-8-iagj" style="width: 100%;"><span
                      class="selection"><span
                      class="select2-selection select2-selection--single form-select form-select-solid" role="combobox"
                      aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
                      aria-labelledby="select2-69zs-container" aria-controls="select2-69zs-container"><span
                      class="select2-selection__rendered" id="select2-69zs-container" role="textbox"
                      aria-readonly="true" title="Status"><span
                      class="select2-selection__placeholder">Status</span></span><span class="select2-selection__arrow"
                                                                                       role="presentation"><b
                      role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                         aria-hidden="true"></span></span>
                    <!--end::Select2-->
                  </div>
                  <!--begin::Add product-->
                  <!--                  <a href="javascript:void(0)" class="btn btn-primary">Thêm mới</a>-->
                  <b-button variant="primary" v-b-modal.modal-create-ads>Thêm mới</b-button>
                  <b-modal id="modal-create-ads" ref="createAffiliate" hide-footer title="Thêm mới">
                    <div class="row">
                      <div class="col-6">
                        <div v-if="this.imageUrl">
                          <img :src="this.imageUrl" alt="Image Preview"/>
                        </div>
                        <div v-else>
                          <div v-if="this.dataForm.imageUrl">
                            <img :src="this.dataForm.imageUrl" alt="Image Preview"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="file" @change="onFileChange" accept="image/*"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="required form-label">Sản phẩm</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input type="text" name="product_name" v-model="dataForm.name" class="form-control mb-2" placeholder="Tên sản phẩm"
                               value="">
                        <!--end::Input-->
                        <!--begin::Description-->
                        <!--                        <div class="text-muted fs-7">A product name is required and recommended to be unique.</div>-->
                        <!--                        &lt;!&ndash;end::Description&ndash;&gt;-->
                        <!--                        <div class="fv-plugins-message-container invalid-feedback"></div>-->
                      </div>
                    </div>
                    <div class="row">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="required form-label">Link</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input type="text" name="product_name" v-model="dataForm.url" class="form-control mb-2" placeholder="Link affiliate"
                               value="">
                        <!--end::Input-->
                        <!--begin::Description-->
<!--                        <div class="text-muted fs-7">A product name is required and recommended to be unique.</div>-->
<!--                        &lt;!&ndash;end::Description&ndash;&gt;-->
<!--                        <div class="fv-plugins-message-container invalid-feedback"></div>-->
                      </div>
                    </div>
                    <div class="row">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="required form-label">Giá</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input type="number" name="product_price" v-model="dataForm.price" class="form-control mb-2" placeholder="Giá sản phẩm"
                               value="">
                        <!--end::Input-->
                        <!--begin::Description-->
<!--                        <div class="text-muted fs-7">A product name is required and recommended to be unique.</div>-->
<!--                        &lt;!&ndash;end::Description&ndash;&gt;-->
<!--                        <div class="fv-plugins-message-container invalid-feedback"></div>-->
                      </div>
                    </div>
                    <div class="row">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="required form-label">Hoa hồng %</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input type="number" name="discountPercent" v-model="dataForm.discountPercent" class="form-control mb-2" placeholder="% hoa hồng nhận "
                               value="">
                        <!--end::Input-->
                        <!--begin::Description-->
<!--                        <div class="text-muted fs-7">A product name is required and recommended to be unique.</div>-->
<!--                        &lt;!&ndash;end::Description&ndash;&gt;-->
<!--                        <div class="fv-plugins-message-container invalid-feedback"></div>-->
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="required form-label">Nguồn</label>
                        <!--end::Label-->
                          <div class="row">
                            <div class="col-6 mb-2">
                              <b-form-radio v-model="dataForm.platform" :aria-describedby="ariaDescribedby" name="some-radios" value="SHOPEE">Shopee</b-form-radio>
                            </div>
                            <div class="col-6 mb-2">
                              <b-form-radio v-model="dataForm.platform" :aria-describedby="ariaDescribedby" name="some-radios" value="LAZADA">Lazada</b-form-radio>
                            </div>
                            <div class="col-6 mb-2">
                              <b-form-radio v-model="dataForm.platform" :aria-describedby="ariaDescribedby" name="some-radios" value="TIKTOK">Tiktok</b-form-radio>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <div class="form-check form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked="checked" v-model="dataForm.active"/>
                          <label class="form-check-label" for="flexCheckChecked">
                            Ẩn/hiện
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="mt-5 fv-row fv-plugins-icon-container">
                        <div class="row">
                          <div class="col-6">
                            <b-button variant="secondary" class="me-3" data-bs-dismiss="modal">Đóng</b-button>
                          </div>
                          <div class="col-6 text-end">
                            <b-button variant="primary" @click="createAffiliate()">Hoàn thành</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                  <!--end::Add product-->
                </div>
                <!--end::Card toolbar-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-0">
                <!--begin::Table-->
                <div id="kt_ecommerce_products_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <b-tabs content-class="mt-3">
                    <b-tab title="Hoạt động" @click="this.filter.active = true; fetchRequest()" :active="this.filter.active"></b-tab>
                    <b-tab title="Đã ẩn" @click="this.filter.active = false; fetchRequest()" :active="!this.filter.active"></b-tab>
                  </b-tabs>
                  <div class="">
                    <div class="fw-bold text-black-50">Tổng số : {{requestItems.metaData.total}}</div>
                    <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                           id="kt_ecommerce_products_table">
                      <!--begin::Table head-->
                      <thead>
                      <!--begin::Table row-->
                      <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="



												" style="width: 29.25px;">
                          <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input class="form-check-input" type="checkbox" data-kt-check="true"
                                   data-kt-check-target="#kt_ecommerce_products_table .form-check-input" value="1">
                          </div>
                        </th>
                        <th class="min-w-200px sorting" tabindex="0" aria-controls="kt_ecommerce_products_table"
                            rowspan="1" colspan="1" aria-label="Product: activate to sort column ascending"
                            style="width: 489.055px;">Image
                        </th>
                        <th class="min-w-200px sorting" tabindex="0" aria-controls="kt_ecommerce_products_table"
                            rowspan="1" colspan="1" aria-label="Product: activate to sort column ascending"
                            style="width: 489.055px;">Product
                        </th>
                        <th class="text-end min-w-100px sorting" tabindex="0"
                            aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                            aria-label="SKU: activate to sort column ascending" style="width: 251.242px;">Platform
                        </th>
                        <th class="text-end min-w-70px sorting" tabindex="0" aria-controls="kt_ecommerce_products_table"
                            rowspan="1" colspan="1" aria-label="Qty: activate to sort column ascending"
                            style="width: 241.633px;">Link
                        </th>
                        <th class="text-end min-w-70px sorting" tabindex="0" aria-controls="kt_ecommerce_products_table"
                            rowspan="1" colspan="1" aria-label="Qty: activate to sort column ascending"
                            style="width: 241.633px;">Created
                        </th>
                        <th class="text-end min-w-100px sorting" tabindex="0"
                            aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                            aria-label="Status: activate to sort column ascending" style="width: 251.242px;">Status
                        </th>
                        <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="Actions"
                            style="width: 245.844px;">Actions
                        </th>
                      </tr>
                      <!--end::Table row-->
                      </thead>
                      <!--end::Table head-->
                      <!--begin::Table body-->
                      <tbody class="fw-bold text-gray-600">
                      <!--begin::Table row-->
                      <tr class="even" v-for="item in this.requestItems.data" :key="item.id">
                        <!--begin::Checkbox-->
                        <td>
                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" value="1">
                          </div>
                        </td>
                        <!--end::Checkbox-->
                        <!--begin::Category=-->
                        <td>
                          <div class="d-flex align-items-center">
                            <!--begin::Thumbnail-->
                            <a href=""
                               class="symbol symbol-100px">
                              <img class="symbol-label" :src="$getImage(item.imageUrl)" alt="">
                            </a>
                            <!--end::Thumbnail-->
                          </div>
                        </td>
                        <!--end::Category=-->
                        <!--begin::SKU=-->
                        <td class="text-start pe-0">
                          <span class="fw-bolder">{{ item.name }}</span> <br>
                          <div class="mt-2">
                            <span class="mt-2">Giá: <b>{{ $numberFormat(item.price) }}</b></span> <br>
                            <span class="mt-2">Hoa hồng: <b>{{ item.discountPercent }} %</b></span> <br>
                            <span class="">Lượt click: <b>{{ item.countClick }}</b> </span> <br>
                            <span class="">Lượt hiển thị: <b>{{ item.countView }}</b> </span> <br>
                            <span class="">Tỉ lệ quan tâm: <b>{{ parseFloat(item.countClick *100 /item.countView).toFixed(2) }} %</b></span>
                          </div>
                        </td>
                        <!--end::SKU=-->
                        <!--begin::Qty=-->
                        <td class="text-end pe-0" data-order="41">
                          <span class="fw-bolder ms-3">{{ item.platform }}</span>
                        </td>
                        <!--end::Qty=-->
                        <td class="text-end pe-0">
                          <span class="fw-bolder text-dark">
                            <a :href="item.url" target="_blank"><i class="bi bi-link" style="font-size: 24px"></i></a>
                          </span>
                        </td>
                        <!--begin::Price=-->
                        <td class="text-end pe-0">
                          <span class="fw-bolder text-dark">{{ $formatDateTime2(item.updatedAt) }}</span>
                        </td>


                        <!--end::Price=-->
                        <!--begin::Status=-->
                        <td class="text-end pe-0" data-order="Published">
                          <!--begin::Badges-->
                          <div class="badge badge-light-success" v-if="item.active">
                            Active
                          </div>
                          <div class="badge badge-light-danger" v-else>
                            Inactive
                          </div>
                          <!--end::Badges-->
                        </td>
                        <!--end::Status=-->
                        <!--begin::Action=-->
                        <td class="text-end" >
                          <a href="javascript:void(0)" class="btn btn-sm btn-light btn-active-light-primary btn-active" data-kt-menu-trigger="click" ref="parentElement"
                             data-kt-menu-placement="bottom-end" @click.stop="this.actionActiveToggle(item.id)"><span class="me-1">Hành động</span><i class="bi bi-chevron-down"></i></a>
                          <!--begin::Menu-->
                          <div
                              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                              :class="{ show: this.actionActive === item.id }"
                              data-kt-menu="true"
                          >
                            <!--begin::Menu item-->
                            <div class="menu-item px-3">
                              <span href="javascript:void(0)" class="menu-link px-3" @click="update(item)" v-b-modal.modal-create-ads>Sửa</span>
                            </div>
                            <div class="menu-item px-3" v-if="item.active">
                              <a href="javascript:void(0)" class="menu-link px-3" @click="changeState(item.id)">Ẩn</a>
                            </div>
                            <div class="menu-item px-3" v-else>
                              <a href="javascript:void(0)" class="menu-link px-3" @click="changeState(item.id)">Hiện</a>
                            </div>
                            <!--end::Menu item-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3">
                              <a href="javascript:void(0)" class="menu-link px-3" @click="deleteItem(item.id)">Xoá</a>
                            </div>
                            <!--end::Menu item-->
                          </div>
                          <!--end::Menu-->
                        </td>
                        <!--end::Action=-->
                      </tr>
                      </tbody>
                      <!--end::Table body-->
                    </table>
                  </div>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Products-->
          </div>
          <!--end::Container-->
        </div>
      </div>
      <!--end::Wrapper-->
    </div>

  </div>
</template>
<script>
import requestUtil from "@/utils/request-util";
import RequestUtil from "@/utils/request-util";
import axios from 'axios';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Affiliate',
  data() {
    return {
      requestItems: {
        data: [],
        metaData: {}
      },
      filter: {
        active: true
      },
      imageFile: null,
      actionActive: null,
      imageUrl: null,
      dataForm: {
        imageUrl: null,
        name: null,
        url: null,
        active: true,
        platform: 'SHOPEE',
        price: 0,
        discountPercent: 0,
        id: null,
      },
      changeStateDto: {
        id: null,
        state: null,
        note: null
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside);
    });
    this.fetchRequest();
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  created() {
  },
  methods: {
    actionActiveToggle(index) {
      this.actionActive = index;
    },
    handleClickOutside() {
      const parentElement = this.$refs.parentElement;
      if (parentElement) {
        this.actionActive = null;
      }
    },
    update(item) {
      Object.keys(this.dataForm).forEach(key => {
        this.dataForm[key] = item[key];
      });
      this.imageUrl = this.$getImage(item.imageUrl)

    },
    async createAffiliate() {
      if (this.dataForm.url === null || this.dataForm.url === "") {
        this.$notify({
          title: 'URL không để trống',
          type: 'error',
          text: 'Vui lòng thử lại'
        });
        return;
      }
      const response = await RequestUtil.post('affiliate', this.dataForm);
      if (response.status === 200) {
        await this.fetchRequest();
        this.$refs.createAffiliate.hide;
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
      } else {
        this.$notify({
          title: 'Lỗi hệ thống',
          type: 'warn',
        });
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.imageFile = file
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
        this.uploadFile();
      } else {
        this.imageUrl = null;
      }
    },
    async uploadFile() {
      let formData = new FormData()
      formData.append('files', this.imageFile)
      const response = await axios.post('https://api.mottruyen.vn/api/v1/storage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status !== 200) {
        this.$notify({
          title: 'Upload ảnh lỗi',
          type: 'error',
          text: 'Vui lòng thử lại'
        });
        return false
      } else {
        this.dataForm.imageUrl = response.data[0]
        return true;
      }
    },
    async fetchRequest() {
      const response = await requestUtil.get('/affiliate/_private?active=' + this.filter.active);
      if (response.status === 200) {
        this.requestItems = response.data
      } else {
        this.$notify({
          title: 'Lỗi hệ thống',
          type: 'warn',
          text: response.data.message
        });
      }
    },
    async changeState(id) {
      const response = await requestUtil.post('/affiliate/'+ id+"/change_status");
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchRequest();
      } else {
        this.$notify({
          title: 'Lỗi hệ thống',
          type: 'warn',
        });
      }
    },
    async deleteItem(id) {
      const response = await requestUtil.post('/affiliate/'+ id+"/_delete");
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchRequest();
      } else {
        this.$notify({
          title: 'Lỗi hệ thống',
          type: 'warn',
        });
      }
    },
  },
};

</script>
<style>
.menu-sub-dropdown{
  position: absolute;
  z-index: 10000;
}
</style>