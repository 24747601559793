// store/index.js
import { createStore } from 'vuex';

export default createStore({
    state: {
        isLoggedIn: false,
    },
    mutations: {
        login(state) {
            state.isLoggedIn = true;
        },
        logout(state) {
            state.isLoggedIn = false;
        }
        // Các mutation khác
    },
    actions: {
        async login({ commit }) {
            commit('login');
            localStorage.setItem('auth', 'true');
        },
        async doLogout({ commit }) {
            commit('logout');
            localStorage.setItem('token', '');
            localStorage.setItem('auth', '');
            localStorage.setItem('user_id', '');
            localStorage.setItem('is_admin', '');
            localStorage.setItem('name', '');
            localStorage.setItem('email', '');
            localStorage.setItem('auth', 'false');
        },
    }
});
