<template>
  <div id="app">
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <Menu v-if="isAuthenticated"></Menu>
        <router-view/>
      </div>
    </div>
  </div>
  <notifications/>
</template>

<script>

import Menu from "@/components/commons/Menu.vue";
import {Notifications} from "@kyvg/vue3-notification";
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {Notifications, Menu},
  computed: {
    ...mapState(['isLoggedIn']) // Map state 'isLoggedIn' từ Vuex store
  },
  data: function () {
    return {
      isAuthenticated: true,
    }
  },
  watch: {
    '$route': 'checkAuthState'
  },
  mounted() {
    this.isAuthenticated = localStorage.getItem('auth');
  },
  methods: {
    checkAuthState() {
      const newAuthState = localStorage.getItem('auth') === 'true';
      this.isAuthenticated = newAuthState;
    }
  },
  created() {
    this.checkAuthState();
  }
}
</script>

<style>
body{
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img{
  width: 100%;
}
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
