<template>
  <span class="author-name">
    <span class="me-2 fw-bold">
    {{ author.name }}
    </span>
    <span v-if="showVerify" class="badge-author">
      <i v-if="author.verifyStatus" class="bi bi-patch-check-fill verify-author-tag"></i>
      <AuthorRank :rank="author.ranking"/>
    </span>
  </span>
</template>

<script>
import AuthorRank from "@/components/authors/AuthorRank.vue";

export default {
  components: {AuthorRank},
  props: {
    author: {
      type: Object,
      required: true
    },
    showVerify: {
      type: Boolean,
      required: true,
      defaults: true
    }
  },
}
</script>



