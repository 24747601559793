export default {
    install: (app) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$getImage = (path) => {
            return `${process.env.VUE_APP_IMAGE_URL}/api/v1/storage${path}`;
        }
        app.config.globalProperties.$formatDateTime2 = (value) => {
            const [year, month, day, hour, min, second] = value.map(unit => unit.toString().padStart(2, '0'));
            return `${year}-${month}-${day} ${hour}:${min}:${second}`;
        }
        app.config.globalProperties.$formatDateTime = (value) => {
            if (!value) return null;
            const m = new Date(value);
            m.setUTCHours(m.getUTCHours() + 7); // Adjust to UTC+7
            return `${m.getUTCFullYear()}/${(m.getUTCMonth() + 1).toString().padStart(2, '0')}/${m.getUTCDate().toString().padStart(2, '0')} ${m.getUTCHours().toString().padStart(2, '0')}:${m.getUTCMinutes().toString().padStart(2, '0')}`;
        }
        app.config.globalProperties.$formatDateTime3 = (value) => {
            if (!value) return null;
            const m = new Date(value);
            m.setUTCHours(m.getUTCHours() + 7); // Adjust to UTC+7
            return `${m.getUTCFullYear()}/${(m.getUTCMonth() + 1).toString().padStart(2, '0')}/${m.getUTCDate().toString().padStart(2, '0')}`;
        }
        app.config.globalProperties.$numberFormat = (value) => {
            return (value ?? 0).toLocaleString('en-US');
        }
        app.config.globalProperties.$numberFormat2 = (number) =>{
            if (number >= 1000000000) {
                return `${(number / 1000000000).toFixed(2)}B`;
            } else if (number >= 1000000) {
                return `${(number / 1000000).toFixed(2)}M`;
            } else if (number >= 1000) {
                return `${(number / 1000).toFixed(1)}K`;
            }
            return number.toString();
        }
        app.config.globalProperties.$isValidPhoneNumber = (phoneNumber) =>{
            const phoneNumberRegex = /^(84|0[3|5|7|8|9])[0-9]{8}$/;
            return phoneNumberRegex.test(phoneNumber);
        }
    }
}