<template>
  <div class="d-flex flex-column flex-root" @click="checkClickOutside">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Cài đặt chung
              </h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Navbar-->
            <!--end::Container-->
            <b-tabs content-class="mt-3">
              <b-tab title="Xu"><p>Đang phát triển</p></b-tab>
              <b-tab title="Banner Ads" active>
                <AffiliateBanner />
              </b-tab>
            </b-tabs>
            <!--end::Row-->
          </div>
          <!--end::Container-->
        </div>
      </div>

      <!--end::Wrapper-->
    </div>

  </div>
</template>
<script>

import AffiliateBanner from "@/components/settings/AffiliateBanner.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Setting',
  components: {AffiliateBanner},
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  created() {
  },
  methods: {
  },
};

</script>
<style>
.menu-sub-dropdown {
  position: absolute;
  z-index: 10000;
}
.banner{
  border-radius: 20px;
  overflow: hidden;
}
.nav-tabs {
  .nav-item {
    color: #a1a5b7;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: color .2s ease, background-color .2s ease;
    padding: .5rem 0;
    margin: 0 1rem;
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 1.15rem !important;
    background: none;

    .nav-link {
      background: none !important;
      color: #5e6278;
      font-weight: 600 !important;
      border: none
    }

    .active {
      border-bottom: 3px solid #009ef7 !important;
      color: #009ef7 !important;

    }
  }
}
</style>