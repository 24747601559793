export default [
    {
        title : 'Dashboard',
        name : 'dashboard',
        icon: '',
    },
    {
        title : 'Nạp rút',
        name : 'wallet_request',
        icon: '',
    },
    {
        title : 'Truyện',
        name : 'story',
        icon: '',
    },
    {
        title : 'Tác giả',
        name : 'author',
        icon: '',
    },
    {
        title : 'Affiliate',
        name : 'affiliate',
        icon: '',
    },
    {
        title : 'Cài đặt chung',
        name : 'setting',
        icon: '',
    }
]